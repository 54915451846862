import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type ReproductionHdrAndPrRow_ReproductionHdrAndPrRowByDayInMilk_Fragment = { __typename: 'ReproductionHdrAndPrRowByDayInMilk', dayInMilk: number, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null };

export type ReproductionHdrAndPrRow_ReproductionHdrAndPrRowByDate_Fragment = { __typename: 'ReproductionHdrAndPrRowByDate', date: string, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null };

export type ReproductionHdrAndPrRowFragment = ReproductionHdrAndPrRow_ReproductionHdrAndPrRowByDayInMilk_Fragment | ReproductionHdrAndPrRow_ReproductionHdrAndPrRowByDate_Fragment;

export const ReproductionHdrAndPrRowFragmentDoc = gql`
    fragment ReproductionHdrAndPrRow on ReproductionHdrAndPrRow {
  __typename
  hashID
  fitForInsemination
  inseminated
  hdr
  fitForPregnancy
  pregnant
  pr
  notPregnantPercent
  cr
  abortions
  ... on ReproductionHdrAndPrRowByDayInMilk {
    dayInMilk
  }
  ... on ReproductionHdrAndPrRowByDate {
    date
  }
}
    `;