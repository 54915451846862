import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionHdrAndPrRowFragmentDoc } from './reproductionHdrAndPrRow.graphql';
export type ReproductionHdrAndPrCalculatedReportFragment = { __typename: 'ReproductionHdrAndPrCalculatedReport', rows: Array<{ __typename: 'ReproductionHdrAndPrRowByDayInMilk', dayInMilk: number, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null } | { __typename: 'ReproductionHdrAndPrRowByDate', date: string, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null }>, total?: { __typename: 'ReproductionHdrAndPrRowByDayInMilk', dayInMilk: number, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null } | { __typename: 'ReproductionHdrAndPrRowByDate', date: string, hashID: string, fitForInsemination?: number | null, inseminated?: number | null, hdr?: number | null, fitForPregnancy?: number | null, pregnant?: number | null, pr?: number | null, notPregnantPercent?: number | null, cr?: number | null, abortions?: number | null } | null };

export const ReproductionHdrAndPrCalculatedReportFragmentDoc = gql`
    fragment ReproductionHdrAndPrCalculatedReport on ReproductionHdrAndPrCalculatedReport {
  __typename
  rows {
    ...ReproductionHdrAndPrRow
  }
  total {
    ...ReproductionHdrAndPrRow
  }
}
    ${ReproductionHdrAndPrRowFragmentDoc}`;