import R from 'ramda';

import { CustomAppHeaders } from '~/services/gql';

import {
  CreateRoleMutationOptions,
  useCreateRoleMutation,
} from '../gql/mutations/createRole.graphql';
import {
  DeleteRoleMutationOptions,
  useDeleteRoleMutation,
} from '../gql/mutations/deleteRole.graphql';
import { useUpdateRoleMutation } from '../gql/mutations/updateRole.graphql';
import { updateRoleFragment } from '../helpers';
import { RoleFormType } from '../types';

const getRoleInputFromForm = (form: RoleFormType) =>
  R.pick(['name', 'color'], form);

/**
 * Hook for CRUD (without delete logic for a while) actions on a blueprint role
 */
export const useRolesCRUD = () => {
  // Role create logic
  const [createRoleMutation] = useCreateRoleMutation();

  const createRole = (
    companyID: string,
    form: RoleFormType,
    mutationOptions?: Partial<CreateRoleMutationOptions>
  ) =>
    createRoleMutation({
      variables: {
        input: { companyID, ...getRoleInputFromForm(form) },
      },
      context: {
        headers: {
          [CustomAppHeaders.activeCompany]: companyID,
        },
      },
      ...mutationOptions,
    });

  // Role update logic
  const [updateRoleMutation] = useUpdateRoleMutation();

  const updateRole = (companyID: string, role: RoleFormType) =>
    updateRoleMutation({
      variables: {
        id: role.id,
        input: getRoleInputFromForm(role),
      },
      context: {
        headers: {
          [CustomAppHeaders.activeCompany]: companyID,
        },
      },
      optimisticResponse: {
        updateRole: null,
      },
      update: updateRoleFragment(role.id, draft => {
        draft.name = role.name;
        draft.color = role.color;
      }),
    });

  // Role delete logic
  const [deleteRoleMutation] = useDeleteRoleMutation();
  const deleteRole = (
    roleId: string,
    migrateToRoleID: string | null,
    mutationOptions?: Partial<DeleteRoleMutationOptions>
  ) =>
    deleteRoleMutation({
      variables: {
        id: roleId,
        migrateToRoleID,
      },
      optimisticResponse: { deleteRole: null },
      ...mutationOptions,
    });

  return {
    createRole,
    updateRole,
    deleteRole,
  };
};
