import { useEffect } from 'react';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { EmptyState } from '~/shared/components/EmptyState';
import { IconVariants } from '~/shared/components/Icon';
import { Skeleton } from '~/shared/components/Skeleton';
import { useCustomScrollWrapper } from '~/shared/hooks/useCustomScrollWrapper';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';
import { SerializableSearchParams } from '~/shared/types/serialization';

import { DatePeriodPicker, formatDateForBackend } from '~/services/dateTime';
import {
  useArkaNavigation,
  WithSearchParamsValidation,
} from '~/services/navigation';

import { PageHeader } from '~/features/layouts';

import {
  isMonitorEntry,
  isMonitorLaunchCalculatingValue,
  MonitorTable,
  useCalculateMonitorModal,
  useEditMonitorEntryModal,
  useMonitorScheduleSettingsModal,
} from '~/widgets/monitor';
import { useCompanyMonitorScheduleQuery } from '~/widgets/monitor/gql/queries/companyMonitorSchedule.graphql';
import { useMonitorQuery } from '~/widgets/monitor/gql/queries/monitor.graphql';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import styles from './index.module.scss';

interface AnalyticsMonitorSearchParams extends SerializableSearchParams {
  since: string;
  till: string;
}

const MONITOR_POLLING_INTERVAL_MS = 15 * 1000;

const DEFAULT_PERIOD_SINCE = formatDateForBackend(
  dayjs().subtract(1, 'month'),
  true
);
const DEFAULT_PERIOD_TILL = formatDateForBackend(dayjs(), true);

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/monitor/'
)({
  wrapInSuspense: true,
  component: AnalyticsMonitorPage,
  validateSearch: ({
    since,
    till,
  }: WithSearchParamsValidation<AnalyticsMonitorSearchParams>) => ({
    since: since || DEFAULT_PERIOD_SINCE,
    till: till || DEFAULT_PERIOD_TILL,
  }),
});

function AnalyticsMonitorPage() {
  const { since, setSince, till, setTill } =
    useSearchParamsState<AnalyticsMonitorSearchParams>();
  const selectedPeriod = { interval: { since, till } };

  const { open: openEditMonitorEntryModal } = useEditMonitorEntryModal();

  const { open: openMonitorScheduleSettingsModal } =
    useMonitorScheduleSettingsModal();

  const { open: openCalculateMonitorModal } = useCalculateMonitorModal();

  const {
    data: monitorData,
    loading: isLoading,
    startPolling,
    stopPolling,
  } = useMonitorQuery({
    variables: {
      since,
      till,
    },
  });
  const monitorEntries = monitorData?.monitor.edges.map(R.prop('node'));

  const { selectedCompanyId } = useArkaNavigation();
  const { data: monitorScheduleSettingsData } = useCompanyMonitorScheduleQuery({
    variables: {
      id: selectedCompanyId ?? '',
    },
    skip: !selectedCompanyId,
  });
  const monitorSettings = monitorScheduleSettingsData?.company?.monitorSchedule;

  const shouldRefetch = (monitorEntries ?? [])
    .filter(isMonitorEntry)
    .flatMap(R.prop('values'))
    .some(isMonitorLaunchCalculatingValue);

  const { useScrollMeasureRef, renderCustomScrollWrapper } =
    useCustomScrollWrapper({
      autoScrollDeps: [monitorData],
      shouldHideScrollBarsForNoOverflow: false,
    });

  // Refetch monitor entries in case of pending calculation
  useEffect(() => {
    if (shouldRefetch) {
      startPolling(MONITOR_POLLING_INTERVAL_MS);
    }

    return stopPolling;
  }, [shouldRefetch]);

  const shouldShowEmptyState = !isLoading && !monitorEntries?.length;

  return (
    <div className={clsx(layoutStyles.limitedContainer)}>
      {shouldShowEmptyState && (
        <EmptyState
          {...{
            message:
              'Чтобы начать работать с показателями, их необходимо добавить на этот экран',
            buttonProps: {
              children: 'Добавить показатель',
              onPress: () => openEditMonitorEntryModal(),
            },
          }}
        />
      )}
      {!shouldShowEmptyState && (
        <>
          <PageHeader
            {...{
              title: 'Показатели',
              rightContent: (
                <>
                  <Button
                    {...{
                      variant: ButtonVariants.secondary,
                      onPress: () => openEditMonitorEntryModal(),
                      iconVariant: IconVariants.plus,
                    }}
                  >
                    Добавить показатель
                  </Button>
                  <Button
                    {...{
                      variant: ButtonVariants.secondary,
                      iconVariant: IconVariants.settings,
                      onPress: () =>
                        openMonitorScheduleSettingsModal({ monitorSettings }),
                    }}
                  >
                    Настроить авторасчёт
                  </Button>
                  <Button
                    {...{
                      onPress: () => openCalculateMonitorModal(),
                      iconVariant: IconVariants.calendarCheck,
                    }}
                  >
                    Рассчитать
                  </Button>
                </>
              ),
            }}
          />
          <div
            className={clsx(
              panelStyles.largePanel,
              'flex flex-col gap-24 p-24'
            )}
          >
            <DatePeriodPicker
              {...{
                label: 'Период',
                name: 'period',
                value: selectedPeriod,
                className: styles.dateRangePickerItem,
                onValueChange: range => {
                  setSince(range.interval.since ?? DEFAULT_PERIOD_SINCE);
                  setTill(range.interval.till ?? DEFAULT_PERIOD_TILL);
                },
              }}
            />
            <Skeleton isLoading={isLoading}>
              {renderCustomScrollWrapper({
                contentClassName: 'shadow-border',
                scrollBarKey: monitorEntries?.length ?? 0,
                children: (
                  <MonitorTable
                    ref={useScrollMeasureRef}
                    entries={monitorEntries ?? []}
                  />
                ),
              })}
            </Skeleton>
          </div>
        </>
      )}
    </div>
  );
}
