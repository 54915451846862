import React, { useCallback, useMemo } from 'react';

import { MetricFilters } from '~/~legacy/services/AnalyticsService';
import { ListItem } from '~/~legacy/types/keyValue';

import { MDropDown } from '~/shared/components/MDropDown';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { AnalyticsGroupingPeriodEnum } from '~/shared/graphql';

import { DatePeriodPicker, DatePeriodPickerThemes } from '~/services/dateTime';
import { DatePeriod } from '~/services/dateTime';

import { FarmFragment } from '~/entities/farms/gql/fragments/farm.graphql';

import { getPeriodItems } from '../../helpers';
import styles from './index.module.scss';

interface Props {
  filters: MetricFilters;
  farms: FarmFragment[];

  onCahnge: (filters: MetricFilters) => void;
}

export const MetricPageFilters: React.FC<Props> = ({
  filters,
  farms,
  onCahnge,
}) => {
  const periodItems = useMemo(() => {
    return getPeriodItems();
  }, []);

  const farmItems = useMemo(() => {
    const items: ListItem<string>[] = farms.map(farm => {
      return {
        value: farm.id,
        content: farm.name,
      };
    });

    const allFarmsItem: ListItem<string | undefined> = {
      value: undefined,
      content: 'Все фермы',
    };

    return [allFarmsItem, ...items];
  }, [farms]);

  const periodChangeCallback = useCallback(
    (groupingPeriod: AnalyticsGroupingPeriodEnum) => {
      onCahnge({
        ...filters,
        groupingPeriod,
      });
    },
    [filters, onCahnge]
  );

  const farmChangeCallback = useCallback(
    (farmId: string) => {
      onCahnge({
        ...filters,
        farmId,
      });
    },
    [filters, onCahnge]
  );

  const datesRangePeriod: DatePeriod = {
    interval: {
      since: filters.since || '',
      till: filters.till || '',
    },
  };

  const datesRangeChangedCallback = useCallback(
    (newPeriod: DatePeriod) => {
      onCahnge({
        ...filters,
        since: newPeriod.interval.since,
        till: newPeriod.interval.till,
      });
    },
    [filters, onCahnge]
  );

  return (
    <table className={styles.filters}>
      <thead>
        <tr>
          <th>
            <Typography variant={TypographyVariants.descriptionLarge}>
              Отображение по
            </Typography>
          </th>
          <th>
            <Typography variant={TypographyVariants.descriptionLarge}>
              Ферма
            </Typography>
          </th>
          <th>
            <Typography variant={TypographyVariants.descriptionLarge}>
              Период
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <MDropDown
              name="groupingPeriod"
              items={periodItems}
              selectedValue={filters.groupingPeriod}
              onChange={periodChangeCallback}
            />
          </td>
          <td>
            <MDropDown
              name="farmId"
              items={farmItems}
              selectedValue={filters.farmId}
              onChange={farmChangeCallback}
            />
          </td>
          <td>
            <DatePeriodPicker
              name="period"
              value={datesRangePeriod}
              onValueChange={datesRangeChangedCallback}
              theme={DatePeriodPickerThemes.light}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
