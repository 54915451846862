import { useCallback, useEffect, useMemo, useState } from 'react';

import { createFileRoute } from '@tanstack/react-router';

import { useApiData } from '~/~legacy/hooks/useApiData';
import { useApiFeedback } from '~/~legacy/hooks/useApiFeedback';
import { useService } from '~/~legacy/hooks/useService';
import {
  AnalyticsDashboardFilters,
  AnalyticsService,
  AppHerdState,
} from '~/~legacy/services/AnalyticsService';
import { MaslovServices } from '~/~legacy/types/services';

import { MChartModal } from '~/shared/components/MChartModal';
import { AnalyticsMetricPeriodEnum } from '~/shared/graphql';

import { useMyUser } from '~/services/auth';
import { useArkaNavigation } from '~/services/navigation';

import { PageHeader } from '~/features/layouts';

import {
  AnalyticsDashboardFiltersPanel,
  ExpandedChart,
  FILTER_PERIODS_MAP,
} from '~/widgets/analytics';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { HerdStateDashboard } from './components/HerdStateDashboard';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/herd/'
)({
  wrapInSuspense: true,
  component: AnalyticsHerdPage,
});

function AnalyticsHerdPage() {
  const analyticsSvc = useService<AnalyticsService>(
    MaslovServices.AnalyticsService
  );
  const memoizedGetHerdState = useMemo(() => {
    return analyticsSvc.getHerdState.bind(analyticsSvc);
  }, [analyticsSvc]);

  const {
    errors,
    loading,
    reload: loadData,
  } = useApiData(memoizedGetHerdState);

  const [chartInfo, setChartInfo] = useState<ExpandedChart>();

  const [filters, setFilters] = useState<AnalyticsDashboardFilters>({
    metricsPeriod: AnalyticsMetricPeriodEnum.Week,
    groupingPeriod:
      FILTER_PERIODS_MAP[AnalyticsMetricPeriodEnum.Week].groupingPeriod,
    since: FILTER_PERIODS_MAP[AnalyticsMetricPeriodEnum.Week].timespan.since,
    till: FILTER_PERIODS_MAP[AnalyticsMetricPeriodEnum.Week].timespan.till,
  });

  const [state, setState] = useState<AppHerdState>({
    metrics: [],
    cowCountChart: {
      bars: [],
      targets: [],
    },
  });

  const changeFilters = useCallback(
    (newFilters: AnalyticsDashboardFilters) => {
      setFilters(newFilters);
    },
    [setFilters]
  );

  const loadHerdState = useCallback(
    async (newFilters: AnalyticsDashboardFilters) => {
      const result = await loadData(newFilters);
      if (result.success) {
        setState(result.data);
      }
    },
    [loadData, setState]
  );

  useEffect(() => {
    loadHerdState(filters);
  }, [filters]);

  const { errorMessage, loader } = useApiFeedback(errors, loading);

  const { myUser } = useMyUser();
  const { selectedCompanyId } = useArkaNavigation();

  const farms = myUser.farms.filter(
    farm => farm.company.id === selectedCompanyId
  );

  const expand = useCallback((newChartInfo: ExpandedChart) => {
    setChartInfo(newChartInfo);
  }, []);

  const closeModal = useCallback(() => {
    setChartInfo(undefined);
  }, [setChartInfo]);

  const filtersComponent = (
    <AnalyticsDashboardFiltersPanel
      farms={farms}
      filters={filters}
      change={changeFilters}
    />
  );

  const expandedChartModal = chartInfo ? (
    <MChartModal close={closeModal} title={chartInfo.title}>
      {filtersComponent}
      {chartInfo.node}
    </MChartModal>
  ) : undefined;

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader title="Статус стада" />

      {filtersComponent}
      {errorMessage}
      <HerdStateDashboard
        groupingPeriod={filters.groupingPeriod}
        herdState={state}
        expand={expand}
        expanded={Boolean(chartInfo)}
      />
      {loader}
      {expandedChartModal}
    </div>
  );
}
