import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { SomaticCellsReportChartFragmentDoc } from '../fragments/somaticCellsReportChart.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalculateSomaticCellsReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  settings: Types.SetSomaticCellsReportSettingsInput;
}>;


export type CalculateSomaticCellsReportMutation = { __typename?: 'Mutation', calculateSomaticCellsReport: { __typename: 'SomaticCellsReportChart', dates: Array<string>, researchedAnimalsCounts: Array<number | null>, chronicallyIllPercents: Array<number | null>, chronicallyIllCounts: Array<number | null>, illPercents: Array<number | null>, illCounts: Array<number | null>, recoveredPercents: Array<number | null>, recoveredCounts: Array<number | null>, healthyPercents: Array<number | null>, healthyCounts: Array<number | null>, highValueFirstTestMilkingPercents: Array<number | null>, highValueFirstTestMilkingCounts: Array<number | null>, normalValueFirstTestMilkingPercents: Array<number | null>, normalValueFirstTestMilkingCounts: Array<number | null>, probabilityOfRecoveryPercents: Array<number | null>, probabilityOfIllnessPercents: Array<number | null>, cacheCreatedAt: string } | { __typename: 'SomaticCellsReportChartEmpty', dates: Array<string> } };


export const CalculateSomaticCellsReportDocument = gql`
    mutation calculateSomaticCellsReport($id: ID!, $settings: SetSomaticCellsReportSettingsInput!) {
  calculateSomaticCellsReport(id: $id, settings: $settings) {
    __typename
    ... on SomaticCellsReportChartEmpty {
      __typename
      dates
    }
    ...SomaticCellsReportChart
  }
}
    ${SomaticCellsReportChartFragmentDoc}`;
export type CalculateSomaticCellsReportMutationFn = Apollo.MutationFunction<CalculateSomaticCellsReportMutation, CalculateSomaticCellsReportMutationVariables>;

/**
 * __useCalculateSomaticCellsReportMutation__
 *
 * To run a mutation, you first call `useCalculateSomaticCellsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateSomaticCellsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateSomaticCellsReportMutation, { data, loading, error }] = useCalculateSomaticCellsReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useCalculateSomaticCellsReportMutation(baseOptions?: Apollo.MutationHookOptions<CalculateSomaticCellsReportMutation, CalculateSomaticCellsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateSomaticCellsReportMutation, CalculateSomaticCellsReportMutationVariables>(CalculateSomaticCellsReportDocument, options);
      }
export type CalculateSomaticCellsReportMutationHookResult = ReturnType<typeof useCalculateSomaticCellsReportMutation>;
export type CalculateSomaticCellsReportMutationResult = Apollo.MutationResult<CalculateSomaticCellsReportMutation>;
export type CalculateSomaticCellsReportMutationOptions = Apollo.BaseMutationOptions<CalculateSomaticCellsReportMutation, CalculateSomaticCellsReportMutationVariables>;