import React from 'react';

import * as yup from 'yup';

import { ButtonThemes } from '~/shared/components/Button';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { RoleFragment } from '~/services/auth/gql/fragments/role.graphql';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { CustomAppHeaders } from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';
import { Callout } from '~/services/notifications';

import { RoleAsyncSelect } from '../../components';

export interface DeleteRoleModalProps
  extends InjectedModalProps<DeleteRoleModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Role to delete
   */
  role: RoleFragment;
  /**
   * Active company id to load replacement roles for
   */
  companyId?: string;
  /**
   * Called, with the role selected for replacement, when user has confirmed the deletion
   */
  onConfirmed?: (migrateToRoleID: string) => void;
}

const FORM_ID = 'DeleteRoleForm';

const SCHEMA = yup.object({
  migrateToRoleID: yup.string().required(),
});

type DeleteRoleFormType = InferValidatedSchema<typeof SCHEMA>;

export const DeleteRoleModal: React.FC<DeleteRoleModalProps> = ({
  className,
  role,
  companyId,
  onConfirmed,

  close,
}) => {
  const formContext = useForm<DeleteRoleFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const handleSubmit = (form: DeleteRoleFormType) => {
    onConfirmed?.(form.migrateToRoleID);
    close();
  };

  return (
    <Modal
      {...{
        className,
        title: 'Удаление роли',
        submitButtonProps: {
          form: FORM_ID,
          theme: ButtonThemes.destructive,
          children: 'Подтвердить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Callout className="mb-24" title="Необходима замена роли" />
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить роль{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {role.name}
            </Typography>
            ?
          </Typography>

          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            На эту роль назначены карточки. Чтобы её удалить, выберите роль на
            замену.
          </Typography>
        </div>

        <RoleAsyncSelect
          {...{
            queryOptions: {
              variables: {
                excludeIDs: [role.id],
              },
              context: {
                headers: {
                  [CustomAppHeaders.activeCompany]: companyId,
                },
              },
            },
            className: 'mt-24',
            name: 'migrateToRoleID',
            label: 'Роль на замену',
          }}
        />
      </Form>
    </Modal>
  );
};
