import { useState } from 'react';

import { createFileRoute, notFound } from '@tanstack/react-router';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSingleSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { DateFormats, formatDate } from '~/services/dateTime';

import { useReproductionCrReportDetailedFromCacheOrQuery } from '~/entities/reproductionCrReports';

import { PageHeader } from '~/features/layouts';

import { ReproductionCrReportCard } from '~/widgets/analyticsReproduction';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/reproduction_/cr_/$reproductionCrReportId'
)({
  component: ReproductionCrReportPage,
});

function ReproductionCrReportPage() {
  const { reproductionCrReportId } = Route.useParams();

  const {
    fragment: reproductionCrReportFragment,
    isLoading: isReproductionCrReportLoading,
    refetch,
    isRefetching,
  } = useReproductionCrReportDetailedFromCacheOrQuery(reproductionCrReportId);

  const reproductionCrReport =
    reproductionCrReportFragment ?? getSingleSkeletonPlaceholder();

  const [reportKey, setReportKey] = useState(Math.random());

  if (!isReproductionCrReportLoading && !reproductionCrReportFragment) {
    throw notFound();
  }

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          isLoading: isReproductionCrReportLoading,
          title: reproductionCrReport.name,
          description: 'Оплодотворяемость (CR)',
          backLinkProps: {
            from: Route.fullPath,
            to: '..',
            params: true,
          },
          rightContent: (
            <div className="flex items-center gap-16">
              <Typography
                className="text-soft"
                variant={TypographyVariants.bodySmall}
              >
                Составлен по данным от&nbsp;
                {formatDate(
                  reproductionCrReport.calculatedReport?.cacheCreatedAt,
                  DateFormats.shortWithTime
                )}
              </Typography>
              <Button
                {...{
                  iconVariant: IconVariants.update,
                  variant: ButtonVariants.secondary,
                  children: 'Обновить отчёт',
                  isDisabled: isReproductionCrReportLoading,
                  isLoading: isRefetching,
                  onPress: () =>
                    refetch({
                      invalidateCache: true,
                    }).then(() => {
                      setReportKey(Math.random());
                    }),
                }}
              />
            </div>
          ),
        }}
      />
      <Skeleton isLoading={isReproductionCrReportLoading}>
        <ReproductionCrReportCard
          key={reportKey}
          {...{
            reproductionCrReport,
          }}
        />
      </Skeleton>
    </div>
  );
}
