import {
  CompareToPreviousPeriodMode,
  FemaleAnimalKind,
  LactationGroupNumber,
  ReproductionCrReportChartKind,
  ReproductionHdrAndPrXAxisMode,
  ReproductionHdrAndPrYAxisMetric,
  ReproductionYAxisMode,
} from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { DATE_PERIOD_FORM_SCHEMA } from '~/services/dateTime';

import { ColorVariants } from '~/styles/__generated__/token-variants';

import {
  ReproductionHdrAndPrReportFiltersAndGroupingMenuItems,
  ReproductionHdrAndPrYAxisMetricConfig,
} from '../types';

/**
 * Form id for reproduction HDR and PR report edit form
 */
export const REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_ID =
  'ReproductionHdrAndPrReportSettingsForm';

/**
 * Dict with filters and grouping add menu items for custom milking report form
 */
export const REPRODUCTION_HDR_AND_PR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT: Record<
  ReproductionHdrAndPrReportFiltersAndGroupingMenuItems,
  string
> = {
  [ReproductionHdrAndPrReportFiltersAndGroupingMenuItems.filterLactations]:
    'Фильтр по лактации',
  [ReproductionHdrAndPrReportFiltersAndGroupingMenuItems.filterAnimals]:
    'Фильтр по животным',
};

/**
 * Dict with filters and grouping add menu items for custom milking report form
 */
export const REPRODUCTION_HDR_AND_PR_Y_AXIS_METRIC_CONFIGS: Record<
  ReproductionHdrAndPrYAxisMetric,
  ReproductionHdrAndPrYAxisMetricConfig
> = {
  [ReproductionHdrAndPrYAxisMetric.Hdr]: {
    field: 'hdr',
    label: 'Выявление (HDR)',
    chartType: 'bar',
    color: ColorVariants.status05,
    withComparison: true,
  },
  [ReproductionHdrAndPrYAxisMetric.Pr]: {
    field: 'pr',
    label: 'Стельность (PR)',
    chartType: 'bar',
    color: ColorVariants.status04,
    withComparison: true,
  },
  [ReproductionHdrAndPrYAxisMetric.Cr]: {
    field: 'cr',
    label: 'Оплодотворяемость (CR)',
    chartType: 'line',
    color: ColorVariants.success,
  },
  [ReproductionHdrAndPrYAxisMetric.NotPregnant]: {
    field: 'notPregnantPercent',
    label: 'Остаток нестельных в стаде',
    chartType: 'line',
    color: ColorVariants.accent,
  },
};

/**
 * Form schema for reproduction HDR and PR report settings
 */
export const REPRODUCTION_HDR_AND_PR_REPORT_SETTINGS_FORM_SCHEMA = yup.object({
  farmID: yup.string().nullable().default(null), // ID
  period: DATE_PERIOD_FORM_SCHEMA.default(DATE_PERIOD_FORM_SCHEMA.getDefault()),
  femaleAnimalKind: oneOfEnum(FemaleAnimalKind)
    .required()
    .default(FemaleAnimalKind.Cow),
  xAxisMode: oneOfEnum(ReproductionHdrAndPrXAxisMode)
    .required()
    .default(ReproductionHdrAndPrXAxisMode.DaysInMilk),
  yAxisMode: oneOfEnum(ReproductionYAxisMode)
    .required()
    .default(ReproductionYAxisMode.ClosestToMax),
  yAxisMetrics: yup
    .array(oneOfEnum(ReproductionHdrAndPrYAxisMetric).required())
    .default([]),
  yAxisShouldCompareToPreviousPeriod: yup.boolean().default(false),
  yAxisCompareToPreviousPeriodMode: oneOfEnum(CompareToPreviousPeriodMode)
    .nullable()
    .default(CompareToPreviousPeriodMode.PeriodByPeriod),
  yAxisCompareToPreviousPeriodChartKind: oneOfEnum(
    ReproductionCrReportChartKind
  )
    .nullable()
    .default(ReproductionCrReportChartKind.Bar),
  lactationGroupNumbers: yup
    .array(oneOfEnum(LactationGroupNumber).required())
    .nullable()
    .default(null),
  cowIDs: yup.array(yup.string().required()).nullable().default(null),
  voluntaryWaitingPeriodDays: yup.number().required(),
});
