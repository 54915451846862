import { gql } from './fakeGql';

const AnalyticalMetricFragmentGQL = gql`
  fragment AnalyticalMetricFragment on AnalyticalMetric {
    metric
    target
    value
    delta
  }
`;

const AnalyticalTargetFragmentGQL = gql`
  fragment AnalyticalTargetFragment on AnalyticalTarget {
    id
    validSince
    validTill
    metric
    value
  }
`;

export const GetHerdStateGQL = gql`
  ${AnalyticalMetricFragmentGQL}
  ${AnalyticalTargetFragmentGQL}

  query GetHerdState(
    $groupingPeriod: AnalyticsGroupingPeriodEnum!
    $metricsPeriod: AnalyticsMetricPeriodEnum!
    $farmIds: [ID!]
    $since: Date
    $till: Date
    $metrics: [AnalyticalMetricEnum!]!
  ) {
    analytics {
      herdStateMetrics(
        metrics: $metrics
        period: $metricsPeriod
        farmIDs: $farmIds
      ) {
        ...AnalyticalMetricFragment
      }

      herdState(
        groupingPeriod: $groupingPeriod
        farmIDs: $farmIds
        since: $since
        till: $till
      ) {
        herdStateMetricChart(metric: COWS_COUNT) {
          bars {
            date
            value
          }
          targets {
            ...AnalyticalTargetFragment
          }
        }
      }
    }
  }
`;

export const GetHerdMetricGQL = gql`
  ${AnalyticalTargetFragmentGQL}

  query GetHerdMetric(
    $metric: AnalyticalMetricEnum!
    $groupingPeriod: AnalyticsGroupingPeriodEnum!
    $farmIds: [ID!]
    $since: Date
    $till: Date
  ) {
    analytics {
      herdState(
        groupingPeriod: $groupingPeriod
        farmIDs: $farmIds
        since: $since
        till: $till
      ) {
        herdStateMetricChart(metric: $metric) {
          bars {
            date
            value
          }

          targets {
            ...AnalyticalTargetFragment
          }
        }
      }
    }
  }
`;

export const GetAnalyticTargetsGQL = gql`
  ${AnalyticalTargetFragmentGQL}

  query GetAnalyticTargets($metric: AnalyticalMetricEnum, $after: String) {
    analyticalTargets(metric: $metric, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      nodes {
        ...AnalyticalTargetFragment
      }
    }
  }
`;

export const SetAnalyticalMetricTargetGQL = gql`
  mutation SetAnalyticalMetricTarget($input: SetAnalyticalMetricTargetInput!) {
    setAnalyticalMetricTarget(input: $input)
  }
`;

export const SetAnalyticalSettingGQL = gql`
  mutation SetAnalyticalSettings($input: SetAnalyticalSettingSettingInput!) {
    setAnalyticalSettingSetting(input: $input)
  }
`;
