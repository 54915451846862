import { Dow } from '@graphql-types';
import * as yup from 'yup';

/**
 * Days of week display names
 */
export const DAYS_OF_WEEK_DICT: Record<Dow, string> = {
  [Dow.Mon]: 'Понедельник',
  [Dow.Tue]: 'Вторник',
  [Dow.Wed]: 'Среда',
  [Dow.Thu]: 'Четверг',
  [Dow.Fri]: 'Пятница',
  [Dow.Sat]: 'Суббота',
  [Dow.Sun]: 'Воскресенье',
};

/**
 * Days of week, sorted in the correct order
 */
export const DAYS_OF_WEEK = Object.values(Dow);

/**
 * Form schema for a date period.
 */
export const DATE_PERIOD_FORM_SCHEMA = yup.object({
  interval: yup
    .object({
      since: yup.string().default(''), // Date!
      till: yup.string().default(''), // Date!
    })
    .default({
      since: '',
      till: '',
    }),
  daysFromToday: yup.number().notRequired().default(undefined),
});
