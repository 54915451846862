import React from 'react';

import { LinkProps } from '@tanstack/react-router';

import { AppHerdState } from '~/~legacy/services/AnalyticsService';

import {
  AnalyticalMetricEnum,
  AnalyticsGroupingPeriodEnum,
} from '~/shared/graphql';
import { formatInt } from '~/shared/helpers/number';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import { useArkaNavigation } from '~/services/navigation';

import {
  ChartDashboardCard,
  DashboardCard,
  ExpandedChart,
} from '~/widgets/analytics';

import styles from './index.module.scss';

interface Props {
  herdState: AppHerdState;
  groupingPeriod: AnalyticsGroupingPeriodEnum;
  selectedFarmId?: string;

  expanded?: boolean;
  expand?: (chartInfo: ExpandedChart) => void;
}

export const HerdStateDashboard: React.FC<Props> = ({
  herdState,
  groupingPeriod,
  selectedFarmId,
  expand,
  expanded,
}) => {
  const { urlCompanyId } = useArkaNavigation();

  const getCardLinkProps = (metricName: AnalyticalMetricEnum): LinkProps => {
    return {
      to: '/$companyId/user/analytics/metric/$metric',
      params: {
        companyId: urlCompanyId,
        metric: metricName,
      },
      ...wrapConditionalObjectElement(
        selectedFarmId && {
          search: { farmId: selectedFarmId },
        }
      ),
    };
  };

  return (
    <div className={styles.root}>
      <ChartDashboardCard
        chart={herdState.cowCountChart}
        groupingPeriod={groupingPeriod}
        metric={AnalyticalMetricEnum.CowsCount}
        className={styles.card}
        selectedFarmId={selectedFarmId}
        expand={expand}
        expanded={expanded}
      />
      <DashboardCard
        {...{
          metricName: AnalyticalMetricEnum.HeifersCount,
          metric: herdState.metrics.find(
            item => item.metric === AnalyticalMetricEnum.HeifersCount
          ),
          formatValue: formatInt,
          cardLinkProps: getCardLinkProps(AnalyticalMetricEnum.HeifersCount),
        }}
      />
      <DashboardCard
        {...{
          metricName: AnalyticalMetricEnum.CowsInMilkingCount,
          metric: herdState.metrics.find(
            item => item.metric === AnalyticalMetricEnum.CowsInMilkingCount
          ),
          cardLinkProps: getCardLinkProps(
            AnalyticalMetricEnum.CowsInMilkingCount
          ),
          formatValue: formatInt,
        }}
      />
      <DashboardCard
        {...{
          metricName: AnalyticalMetricEnum.CowsInDryCount,
          metric: herdState.metrics.find(
            item => item.metric === AnalyticalMetricEnum.CowsInDryCount
          ),
          cardLinkProps: getCardLinkProps(AnalyticalMetricEnum.CowsInDryCount),
          formatValue: formatInt,
        }}
      />
      <DashboardCard
        {...{
          metricName: AnalyticalMetricEnum.SoldCount,
          metric: herdState.metrics.find(
            item => item.metric === AnalyticalMetricEnum.SoldCount
          ),
          cardLinkProps: getCardLinkProps(AnalyticalMetricEnum.SoldCount),
          formatValue: formatInt,
        }}
      />
      <DashboardCard
        {...{
          metricName: AnalyticalMetricEnum.DeadCount,
          metric: herdState.metrics.find(
            item => item.metric === AnalyticalMetricEnum.DeadCount
          ),
          cardLinkProps: getCardLinkProps(AnalyticalMetricEnum.DeadCount),
          formatValue: formatInt,
        }}
      />
      <DashboardCard
        {...{
          metricName: AnalyticalMetricEnum.BullsCount,
          metric: herdState.metrics.find(
            item => item.metric === AnalyticalMetricEnum.BullsCount
          ),
          cardLinkProps: getCardLinkProps(AnalyticalMetricEnum.BullsCount),
          formatValue: formatInt,
        }}
      />
      <DashboardCard
        {...{
          metricName: AnalyticalMetricEnum.CalvedCowsCount,
          metric: herdState.metrics.find(
            item => item.metric === AnalyticalMetricEnum.CalvedCowsCount
          ),
          cardLinkProps: getCardLinkProps(AnalyticalMetricEnum.CalvedCowsCount),
          formatValue: formatInt,
        }}
      />
    </div>
  );
};
