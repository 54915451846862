import React from 'react';

import * as yup from 'yup';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  DATE_PERIOD_FORM_SCHEMA,
  DatePeriod,
  DatePeriodPicker,
} from '~/services/dateTime';
import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { CowEventsQueryVariables } from '~/entities/cowEvents/gql/queries/cowEvents.graphql';

import { useExportEventsCsv } from '../../hooks';

export interface DownloadCowEventsCsvModalProps
  extends InjectedModalProps<DownloadCowEventsCsvModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Default selected period
   */
  defaultPeriod?: DatePeriod;
  /**
   * Query variables for the cow events query
   */
  queryVariables?: CowEventsQueryVariables;
}

const FORM_ID = 'DownloadCowEventsCsvForm';

const SCHEMA = yup.object({
  period: DATE_PERIOD_FORM_SCHEMA.required(),
});

type DownloadCowEventsCsvFormType = InferValidatedSchema<typeof SCHEMA>;

export const DownloadCowEventsCsvModal: React.FC<
  DownloadCowEventsCsvModalProps
> = ({
  className,
  defaultPeriod = DATE_PERIOD_FORM_SCHEMA.getDefault(),
  queryVariables,

  close,
}) => {
  const formContext = useForm<DownloadCowEventsCsvFormType>({
    schema: SCHEMA,
    defaultValues: { period: defaultPeriod },
  });

  const { exportEventsCsv, isExportCsvLoading } = useExportEventsCsv();

  const handleSubmit = (form: DownloadCowEventsCsvFormType) => {
    exportEventsCsv({
      ...queryVariables,
      ...form.period.interval,
    }).then(close);
  };

  return (
    <Modal
      {...{
        className,
        title: 'История событий в CSV',
        submitButtonProps: {
          form: FORM_ID,
          children: 'Скачать',
          isLoading: isExportCsvLoading,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          className: 'grid gap-24',
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Typography variant={TypographyVariants.bodySmall} tag="p">
          Укажите период, за который необходимо сформировать историю событий
        </Typography>
        <DatePeriodPicker
          {...{
            label: 'Период',
            name: 'period',
          }}
        />
      </Form>
    </Modal>
  );
};
