import { useMemo, useState } from 'react';

import { createFileRoute, Link, useLocation } from '@tanstack/react-router';

import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSingleSkeletonPlaceholder,
  isSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';
import { TextLink } from '~/shared/components/TextLink';
import { Tooltip } from '~/shared/components/Tooltip';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { getTmpId } from '~/shared/helpers/string';

import { useCustomReportDetailedFromCacheOrQuery } from '~/entities/customReports';

import {
  useCustomReportSettingsModal,
  useLaunchCustomReport,
} from '~/features/customReportLaunch';
import { CustomReportLaunchResultFragment } from '~/features/customReportLaunch/gql/fragments/customReportLaunchResult.graphql';
import { PageHeader } from '~/features/layouts';

import { CustomReportChartCard } from '~/widgets/customReportCharts';
import { CustomReportPivotCard } from '~/widgets/customReportPivot';

import layoutStyles from '~/styles/modules/layout.module.scss';

const TOOLTIP_MAX_WIDTH_PX = 264;

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/custom-reports/$customReportId/$launchHashId/'
)({
  component: CustomReportLaunchPage,
});

function CustomReportLaunchPage() {
  const { customReportId = '', launchHashId = '' } = Route.useParams();
  // This state is available, if we open this page from the launching modal
  const { state: customReportLaunchRouterState } = useLocation();

  const { fragment: customReportFragment, isLoading: isCustomReportLoading } =
    useCustomReportDetailedFromCacheOrQuery(customReportId);

  const { open: openCustomReportSettingsModal } =
    useCustomReportSettingsModal();

  const {
    isLaunchLoading,
    customReportLaunchResult: customReportLaunchResultData,
    setLaunchCustomReportResult,
  } = useLaunchCustomReport({
    customReport: customReportFragment,
    launchHashId,
    initialLaunchState: customReportLaunchRouterState.data as
      | CustomReportLaunchResultFragment
      | undefined,
  });

  const customReport = customReportFragment ?? getSingleSkeletonPlaceholder();
  const customReportLaunchResult =
    customReportLaunchResultData ?? getSingleSkeletonPlaceholder();

  // We use this as indicator for new chart creation and
  const [newChartSettingsCardKey, setNewChartSettingsCardKey] = useState('');

  const chartSettingsToRender = useMemo(
    () => [
      ...wrapConditionalArrayElement(
        newChartSettingsCardKey && {
          chartIndex: undefined,
          chartSettings: { id: newChartSettingsCardKey },
        }
      ),
      ...(customReport.chartsSettings ?? []).map(
        (chartSettings, chartIndex) => ({
          chartIndex,
          chartSettings,
        })
      ),
    ],
    [customReport, newChartSettingsCardKey]
  );

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          isLoading: isCustomReportLoading,
          title: customReport?.name,
          backLinkProps: {
            from: Route.fullPath,
            to: '../..',
          },
          descriptionContent: (
            <div className="flex gap-8">
              <Tooltip
                {...{
                  content: 'Нажмите, чтобы открыть список в новой вкладке',
                  maxWidth: TOOLTIP_MAX_WIDTH_PX,
                }}
              >
                <TextLink
                  {...{
                    target: '_blank',
                    className: 'text-soft',
                    from: Route.fullPath,
                    to: '/$companyId/user/analytics/custom-reports/$customReportId/$launchHashId/detail',
                  }}
                >
                  {customReport.blueprint?.name}
                </TextLink>
              </Tooltip>

              {!isCustomReportLoading && !!customReport?.blueprint?.id && (
                <Link
                  {...{
                    target: '_blank',
                    from: Route.fullPath,
                    to: '/$companyId/integrator/blueprints/edit/$blueprintId',
                    params: prev => ({
                      ...prev,
                      blueprintId: customReport?.blueprint?.id ?? '',
                    }),
                  }}
                >
                  <Button
                    {...{
                      variant: ButtonVariants.ghost,
                      iconVariant: IconVariants.edit,
                      size: ButtonSizes.small24,
                      tooltip:
                        'Перейти к редактированию списка в новой вкладке',
                    }}
                  />
                </Link>
              )}
            </div>
          ),
          rightContent: (
            <>
              <Button
                {...{
                  isDisabled: isLaunchLoading,
                  className: 'ml-a',
                  iconVariant: IconVariants.update,
                  variant: ButtonVariants.secondary,
                  onPress: () =>
                    openCustomReportSettingsModal({
                      customReportId,
                      isRestart: true,
                    }),
                }}
              >
                Обновить отчёт
              </Button>
              <Button
                {...{
                  isDisabled: isLaunchLoading,
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.plus,
                  onPress: () =>
                    setNewChartSettingsCardKey(
                      getTmpId('customReportChartSettings')
                    ),
                }}
              >
                Создать диаграмму
              </Button>
            </>
          ),
        }}
      />

      {!isSkeletonPlaceholder(customReport) &&
        !isSkeletonPlaceholder(customReportLaunchResult) &&
        chartSettingsToRender.map(({ chartSettings, chartIndex }) => (
          <CustomReportChartCard
            key={chartSettings.id}
            {...{
              className: 'mb-32',
              chartIndex,
              customReport,
              customReportLaunchResult,
              onCreationFinish: chartData => {
                setNewChartSettingsCardKey('');
                // If chartData is passed, we created a new chart, so we should add it to customReportLaunchResult
                if (chartData && customReportLaunchResult) {
                  setLaunchCustomReportResult({
                    ...customReportLaunchResult,
                    charts: [chartData, ...customReportLaunchResult.charts],
                  });
                }
              },
            }}
          />
        ))}

      <Skeleton isLoading={isLaunchLoading || isCustomReportLoading}>
        <CustomReportPivotCard
          {...{
            customReport,
            customReportLaunchResult,
          }}
        />
      </Skeleton>
    </div>
  );
}
