import {
  CompareToPreviousPeriodMode,
  ConceptionRateParameterEnum,
  Dow,
  FemaleAnimalKind,
  LactationGroupNumber,
  ReproductionCrDateStep,
  ReproductionCrReportChartKind,
  ReproductionYAxisMode,
} from '@graphql-types';
import * as yup from 'yup';

import { oneOfEnum } from '~/shared/helpers/yup';

import { DATE_PERIOD_FORM_SCHEMA } from '~/services/dateTime';

import { ReproductionCrReportFiltersAndGroupingMenuItems } from '../types';

/**
 * Form id for reproduction CR report edit form
 */
export const REPRODUCTION_CR_REPORT_SETTINGS_FORM_ID =
  'ReproductionCrReportSettingsForm';

/**
 * Dict with date x axis possible steps, used in reproduction
 */
export const REPRODUCTION_CR_DATE_STEP_DICT: Record<
  ReproductionCrDateStep,
  string
> = {
  [ReproductionCrDateStep.Day]: 'День',
  [ReproductionCrDateStep.Week]: 'Неделя',
  [ReproductionCrDateStep.Month]: 'Месяц',
};

/**
 * Dict with all possible conception rate grouping parameters
 */
export const REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT: Record<
  ConceptionRateParameterEnum,
  string
> = {
  [ConceptionRateParameterEnum.Bull]: 'Бык',
  [ConceptionRateParameterEnum.Date]: 'Дата',
  [ConceptionRateParameterEnum.Employee]: 'Техник',
  [ConceptionRateParameterEnum.InseminationScheme]: 'Схема осеменения',
  [ConceptionRateParameterEnum.InseminationNumber]:
    'Номер осеменения в лактации',
  [ConceptionRateParameterEnum.Stud]: 'Код поставщика семени',
  [ConceptionRateParameterEnum.IntervalBetweenInseminations]:
    'Интервал между осеменениями',
  [ConceptionRateParameterEnum.DayOfWeek]: 'День недели',
  [ConceptionRateParameterEnum.AnimalCycle]: 'Цикл животного',
  [ConceptionRateParameterEnum.BullBreed]: 'Порода быка',
};

/**
 * Dict with filters and grouping add menu items for custom milking report form
 */
export const REPRODUCTION_CR_REPORT_FILTERS_AND_GROUPING_MENU_ITEMS_DICT: Record<
  ReproductionCrReportFiltersAndGroupingMenuItems,
  string
> = {
  [ReproductionCrReportFiltersAndGroupingMenuItems.filterLactations]:
    'Фильтр по лактации',
  [ReproductionCrReportFiltersAndGroupingMenuItems.filterAnimals]:
    'Фильтр по животным',
  [ReproductionCrReportFiltersAndGroupingMenuItems.grouping]:
    'Группировка по значениям',
};

/**
 * Form schema for CR grouping value used for report filtering
 */
export const REPRODUCTION_CR_VALUE_INPUT_FORM_SCHEMA = yup.object({
  date: yup.object({
    since: yup.string().default('').required(), // Date!
    till: yup.string().default('').required(), // Date!
  }),
  bullID: yup.string().notRequired(), // ID
  employeeID: yup.string().notRequired(), // ID
  inseminationSchemeID: yup.string().notRequired(), // ID
  inseminationNumber: yup.number().notRequired(),
  studCode: yup.string().notRequired(),
  intervalBetweenInseminations: yup
    .object({
      periodStart: yup.number().default(null).required(),
      periodEnd: yup.number().default(null).required(),
    })
    .nullable()
    .default(null),
  dows: oneOfEnum(Dow).notRequired(),
  animalCycle: yup
    .object({
      periodStart: yup.number().default(null).required(),
      periodEnd: yup.number().default(null).required(),
    })
    .nullable()
    .default(null),
  bullBreed: yup.string().notRequired(),
});

/**
 * Form schema for reproduction CR report settings
 */
export const REPRODUCTION_CR_REPORT_SETTINGS_FORM_SCHEMA = yup.object({
  farmID: yup.string().nullable().default(null), // ID
  period: DATE_PERIOD_FORM_SCHEMA.default(DATE_PERIOD_FORM_SCHEMA.getDefault()),
  femaleAnimalKind: oneOfEnum(FemaleAnimalKind)
    .required()
    .default(FemaleAnimalKind.Cow),
  xAxisMode: oneOfEnum(ConceptionRateParameterEnum)
    .required()
    .default(ConceptionRateParameterEnum.Bull),
  xAxisStep: oneOfEnum(ReproductionCrDateStep).nullable().default(null),
  xAxisValues: yup
    .array(REPRODUCTION_CR_VALUE_INPUT_FORM_SCHEMA.required())
    .nullable()
    .default(null),
  xAxisShouldShowOther: yup.boolean().default(false),
  xAxisOtherFromTotalThresholdPercent: yup.number().nullable().default(null),
  yAxisMode: oneOfEnum(ReproductionYAxisMode)
    .required()
    .default(ReproductionYAxisMode.ClosestToMax),
  yAxisShouldCompareToPreviousPeriod: yup.boolean().default(false),
  yAxisCompareToPreviousPeriodMode: oneOfEnum(CompareToPreviousPeriodMode)
    .nullable()
    .default(CompareToPreviousPeriodMode.PeriodByPeriod),
  yAxisCompareToPreviousPeriodChartKind: oneOfEnum(
    ReproductionCrReportChartKind
  )
    .nullable()
    .default(ReproductionCrReportChartKind.Bar),
  lactationGroupNumbers: yup
    .array(oneOfEnum(LactationGroupNumber).required())
    .nullable()
    .default(null),
  cowIDs: yup.array(yup.string().required()).nullable().default(null),
  groupBy: oneOfEnum(ConceptionRateParameterEnum).nullable().default(null),
  groupByValues: yup
    .array(REPRODUCTION_CR_VALUE_INPUT_FORM_SCHEMA.required())
    .nullable()
    .default(null),
  groupByChartKind: oneOfEnum(ReproductionCrReportChartKind)
    .nullable()
    .default(ReproductionCrReportChartKind.Line),
  groupByStep: oneOfEnum(ReproductionCrDateStep)
    .nullable()
    .default(ReproductionCrDateStep.Day),
});
