import { useState } from 'react';

import { createFileRoute, notFound } from '@tanstack/react-router';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import {
  getSingleSkeletonPlaceholder,
  Skeleton,
} from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { DateFormats, formatDate } from '~/services/dateTime';

import { useReproductionHdrAndPrReportDetailedFromCacheOrQuery } from '~/entities/reproductionHdrAndPrReports';

import { PageHeader } from '~/features/layouts';

import { ReproductionHdrAndPrReportCard } from '~/widgets/analyticsReproduction';

import layoutStyles from '~/styles/modules/layout.module.scss';

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/_analyticsLayout/reproduction_/hdr-and-pr_/$reproductionHdrAndPrReportId'
)({
  component: ReproductionHdrAndPrReportPage,
});

function ReproductionHdrAndPrReportPage() {
  const { reproductionHdrAndPrReportId } = Route.useParams();

  const {
    fragment: reproductionHdrAndPrReportFragment,
    isLoading: isReproductionHdrAndPrReportLoading,
    refetch,
    isRefetching,
  } = useReproductionHdrAndPrReportDetailedFromCacheOrQuery(
    reproductionHdrAndPrReportId
  );

  const reproductionHdrAndPrReport =
    reproductionHdrAndPrReportFragment ?? getSingleSkeletonPlaceholder();

  const [reportKey, setReportKey] = useState(Math.random());

  if (
    !isReproductionHdrAndPrReportLoading &&
    !reproductionHdrAndPrReportFragment
  ) {
    throw notFound();
  }

  return (
    <div className={layoutStyles.limitedContainer}>
      <PageHeader
        {...{
          isLoading: isReproductionHdrAndPrReportLoading,
          title: reproductionHdrAndPrReport.name,
          description: 'Выявление (HDR) и Стельность (PR)',
          backLinkProps: {
            from: Route.fullPath,
            to: '..',
            params: true,
          },
          rightContent: (
            <div className="flex items-center gap-16">
              <Typography
                className="text-soft"
                variant={TypographyVariants.bodySmall}
              >
                Составлен по данным от&nbsp;
                {formatDate(
                  reproductionHdrAndPrReport.calculatedReport?.cacheCreatedAt,
                  DateFormats.shortWithTime
                )}
              </Typography>
              <Button
                {...{
                  iconVariant: IconVariants.update,
                  variant: ButtonVariants.secondary,
                  children: 'Обновить отчёт',
                  isDisabled: isReproductionHdrAndPrReportLoading,
                  isLoading: isRefetching,
                  onPress: () =>
                    refetch({
                      invalidateCache: true,
                    }).then(() => {
                      setReportKey(Math.random());
                    }),
                }}
              />
            </div>
          ),
        }}
      />
      <Skeleton isLoading={isReproductionHdrAndPrReportLoading}>
        <ReproductionHdrAndPrReportCard
          key={reportKey}
          {...{
            reproductionHdrAndPrReport,
          }}
        />
      </Skeleton>
    </div>
  );
}
